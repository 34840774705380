<template>
  <Icon
    v-if="locale === 'es'"
    name="kh:apple-wallet-add-es"
    class="aspect-auto w-36 print:hidden"
  />
  <Icon
    v-else
    name="kh:apple-wallet-add-de"
    class="aspect-auto w-36 print:hidden"
  />
</template>

<script setup lang="ts">
const { locale } = useI18n()

defineOptions({
  name: 'IconAppleWallet',
})
</script>

<template>
  <div class="flex flex-col items-center space-y-8 text-center">
    <Icon name="kh:check-circle-outline" class="size-16 fill-system-success" />

    <span class="text-lg font-bold" v-text="t('accountRegisterConfirmed')" />

    <NuxtLink
      v-if="withLink"
      :to="localeRoute({ name: ROUTE_NAMES.ACCOUNT_WATCHLIST })"
      class="button button-primary button-base"
    >
      {{ t('toLogin') }}
    </NuxtLink>

    <Button
      v-else
      :text="t('buttonText')"
      @click="changeState(USER_AUTH_STATE.LOGIN)"
    />
  </div>
</template>

<script setup lang="ts">
interface Props {
  withLink?: boolean
}

withDefaults(defineProps<Props>(), {
  withLink: false,
})

const { t } = useI18n()
const { changeState } = useAuthState()
const localeRoute = useLocaleRoute()

defineOptions({
  name: 'AuthRegisterAccountConfirmed',
})
</script>

<i18n>
de:
  accountRegisterConfirmed: "Account erfolgreich bestätigt!"
  backToLogin: "Zurück zur Anmeldung"
  toLogin: "Zur Anmeldung"
es:
  accountRegisterConfirmed: "¡Cuenta confirmada con éxito!"
  backToLogin: "Volver al inicio de sesión"
  toLogin: "Iniciar sesión"
</i18n>
